import "./style.css";
import { useState, useEffect,useContext } from "react";
import SideBar from "../Sidebar";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
const TopegrHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["scode"]);
  const { globalScodeVariable, setGlobalScodeVariable } =
    useContext(AppContext);
  const userName = localStorage.getItem("name");
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const sessionExpire = () => {
    navigate("/email");
  };

  const handleLogout = () => {
    setGlobalScodeVariable("")
    removeCookie("scode", { path: "/" });
    sessionExpire();
  };

  return (
    <>
      <div className="containerHeader">
        <div className="headerIcons">
          <button className="toggle-button" onClick={toggleSidebar}>
            ☰
          </button>
          <label
            style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}
          >
            TopEngr
          </label>
        </div>
        <label className="welcomeMsg">Welcome {userName}</label>
        <div
          className="logout btn btn-light d-flex align-items-center p-1 px-2"
          onClick={handleLogout}
        >
          <span className="logout-text me-2 fw-bold">Logout</span>
          <i className="bi bi-box-arrow-right"></i>
        </div>
      </div>
      <div className=" w-25">
        {isOpen && <SideBar onClose={toggleSidebar} />}
      </div>
    </>
  );
};

export default TopegrHeader;
