import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const SideBar = ({ onClose }) => {
  return (
    <motion.div
      className=" bg-blue sidebar"
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      transition={{ type: "tween", stiffness: 70 }}
    >
      <div className="d-flex">
        <div className="d-flex flex-column align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <ul
            className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start"
            id="menu"
          >
            <li className="nav-item d-flex justify-content-between align-items-center fs-2 mt-2 mb-3 w-100">
              <img
                src="favicon.ico"
                height={33}
                width={33}
                className="ms-2 bg-white rounded p-1"
              />
              <i
                className="bi bi-x-lg"
                onClick={() => onClose()}
                style={{ fontSize: "20px" }}
              ></i>
            </li>
            <li className="nav-item custom-nav-item align-items-start align-items-sm-start">
              <NavLink
                to="/jobList"
                className="nav-link align-self-start w-100"
                activeClassName="active-link"
                onClick={() => onClose()}
              >
                <i
                  className="bi bi-house"
                  style={{
                    fontWeight: "bold",
                    fontSize: "21px",
                    color: "white",
                    marginRight: "5px",
                  }}
                ></i>
                <span className="ms-1 textcolor">Home</span>
              </NavLink>
            </li>

            <li class="nav-item custom-nav-item">
              <NavLink
                to="/searchQueryList"
                className="nav-link  align-middle"
                activeClassName="active-link"
                onClick={() => onClose()}
              >
                <i
                  className="bi bi-grid-fill color rounded-circle"
                  style={{ color: "white", marginRight: "5px", fontSize: 20 }}
                ></i>
                <span className="ms-1" style={{ color: "white" }}>
                  Search Query
                </span>
              </NavLink>
            </li>
            <li className="nav-item custom-nav-item align-items-start align-items-sm-start">
              <NavLink
                to="/quizassess"
                className="nav-link align-self-start w-100"
                activeClassName="active-link"
                onClick={() => onClose()}
              >
                <i
                  className="bi bi-house"
                  style={{
                    fontWeight: "bold",
                    fontSize: "21px",
                    color: "white",
                    marginRight: "5px",
                  }}
                ></i>
                <span className="ms-1 textcolor">Quiz Assessment</span>
              </NavLink>
            </li>
            <li class="nav-item custom-nav-item">
              <NavLink
                to="/profile"
                className="nav-link  align-middle"
                activeClassName="active-link"
                onClick={() => onClose()}
              >
                <i
                  className="bi bi-person-circle color rounded-circle"
                  style={{ color: "white", marginRight: "5px", fontSize: 20 }}
                ></i>
                <span className="ms-1" style={{ color: "white" }}>
                  Profile
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default SideBar;
