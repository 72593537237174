import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useCookies } from "react-cookie";
import Header from "../Header";
import AuthHeader from "./authHeader";
const Email = () => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  let server = "https://developer.topengr.com/api";

  const validateEmail = (email) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email);
  };
  const handleNotify = () => {
    if (email === "") {
      setErr("Please Enter Email");
    } else if (!validateEmail(email)) {
      setErr("Please Enter Valid Email");
    } else if (!isChecked) {
      setErr("Please agree to term and conditions");
    } else if (email !== "" && validateEmail(email)) {
      localStorage.setItem("email", email);
      fetch(server + "/v1/ts/cn/signin1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ consent: "x", email: email }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type === 3) {
            setErr(data.message);
          } else {
            navigate("/otp");
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  return (
    <>
      <AuthHeader />
      <div className="containerH">
        <div className="content">
          <p style={{ textAlign: "center", fontFamily: "sans-serif" }}>
            Please Enter valid Email Address
          </p>

          <div>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email"
              className="input"
            />
            <div
              className="checkbox-container"
              style={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <label htmlFor="checkbox" style={{ fontFamily: "sans-serif" }}>
                Accept terms and conditions
              </label>
            </div>
            {err && (
              <div
                className="error"
                style={{ marginTop: "5px", marginLeft: "10px" }}
              >
                {err}
              </div>
            )}

            <button
              className="button"
              onClick={handleNotify}
              style={{
                display: "block",
                margin: "10px auto 0px",
                textAlign: "center",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email;
