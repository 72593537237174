import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import TopegrHeader from "../TopengrHeader";
const QuizStart = () => {
  const navigate = useNavigate();
  const handleQuiz = () => {
    navigate("/quiz");
  };
  return (
    <>
      <TopegrHeader />
      <div className="quizcontainer">
        <h2 className=" mb-5">Assessments</h2>
        <div className="homeContainer">
          <h2>Quiz Assessment</h2>
          <button onClick={handleQuiz}>Start Your Test</button>
        </div>
      </div>
    </>
  );
};

export default QuizStart;
