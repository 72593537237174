// AppContext.js
import React, { createContext, useState } from "react";
import { useCookies } from "react-cookie";
export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [cookies] = useCookies(["scode"]);
  const scode = cookies.scode ? cookies.scode : "";
  const [globalScodeVariable, setGlobalScodeVariable] = useState(scode);

  return (
    <AppContext.Provider
      value={{ globalScodeVariable, setGlobalScodeVariable }}
    >
      {children}
    </AppContext.Provider>
  );
};
