import React, { useState, useContext } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./style.css";
import AuthHeader from "./authHeader";
import { AppContext } from "../AppContext";

const OTP = () => {
  const { globalScodeVariable, setGlobalScodeVariable } =
    useContext(AppContext);
  const [otp, setOtp] = useState("");
  const [err, setErr] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const navigate = useNavigate();
  let server = "https://developer.topengr.com/api";
  const email = localStorage.getItem("email");
  const handleVerify = () => {
    if (otp.length === 6) {
      console.log(email, "otp step +++++++++++++++");
      fetch(server + "/v1/ts/cn/signin2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, vcode: otp }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log(JSON.stringify(data.scode), "3rd API++++++++++++");
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 1);
          if (isChecked) {
            setCookie("scode", data.scode, {
              path: "/",
              expires: expirationDate,
            });
            setGlobalScodeVariable(data.scode);
          } else {
            setGlobalScodeVariable(data.scode);
          }
          console.log(globalScodeVariable);
          navigate("/jobList");
        })
        .catch((error) => console.error("Error:", error));
    } else {
      setErr("Please Enter OTP");
    }
  };
  return (
    <>
      <AuthHeader />
      <div className="containerH">
        <div className="content">
          <p style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>
            Email Verification
          </p>
          <p style={{ fontFamily: "sans-serif" }}>
            OTP has been send to your email please check and enter
          </p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputType="number"
            containerStyle={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            inputStyle={{
              height: "30px",
              width: "30px",
              borderRadius: "5px",
              border: " 1px solid",
              margin: "5px",
            }}
            renderInput={(props) => <input className="OTP" {...props} />}
          />
          <div
            className="checkbox-container"
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <label htmlFor="checkbox" style={{ fontFamily: "sans-serif" }}>
              Do you regularly use this device?
            </label>
          </div>

          <button className="button" onClick={handleVerify}>
            Verify Email
          </button>
          {err && <div className="error text-start">{err}</div>}
        </div>
      </div>
    </>
  );
};

export default OTP;
