import "./App.css";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from "./Profile";
import { CookiesProvider, useCookies } from "react-cookie";
import Email from "./Authentcation/email";
import OTP from "./Authentcation/verification";
import SearchQueryList from "./SearchJobList";
import { AppProvider } from "./AppContext";
import QuizStart from "./home";
import QuizContainer from "./quizConatiner";
import SubmitTest from "./SubmitTest";

const JobList = lazy(() => import("./JobList"));

function App() {
  const headerType = "topengr";
  const [cookies] = useCookies(["scode"]);
  const scode = cookies.scode;
  localStorage.setItem("headerType", headerType);
  return (
    <div>
      <AppProvider>
        <CookiesProvider>
          <BrowserRouter basename="/app/">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={scode ? <JobList /> : <Email />} />
                <Route path="/jobList" element={<JobList />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/email" element={<Email />} />
                <Route path="/otp" element={<OTP />} />
                <Route path="/searchQueryList" element={<SearchQueryList />} />
                <Route path="/quizassess" element={<QuizStart />} />
                <Route path="/quiz" element={<QuizContainer />} />
                <Route path="/submitAssess" element={<SubmitTest />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </CookiesProvider>
      </AppProvider>
    </div>
  );
}

export default App;
