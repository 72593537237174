import "./style.css";
import { useState, useEffect } from "react";
import SideBar from "../Sidebar";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="containerHeader">
        <div className="headerIcons">
          <button className="toggle-button" onClick={toggleSidebar}>
            ☰
          </button>
          <img className="logo" src="logo.png" />
        </div>
        <label className="welcomeMsg">welcom sak@stk</label>
      </div>
      <div className=" w-25">
        {isOpen && <SideBar onClose={toggleSidebar} />}
      </div>
    </>
  );
};

export default Header;
