// import React, { useState } from "react";
// import "./style.css";
// import Header from "../header";
// import { useNavigate } from "react-router-dom";

// const QuizContainer = () => {
//   const navigate = useNavigate();
//   const questions = [
//     {
//       question: "Which language is used for backend? hyhghghghghhhjhjjjj",
//       options: ["Html", "CSS", "React", "Java"],
//       type: "single",
//     },
//     {
//       question: "What does CSS stand for?",
//       options: [
//         "Cascading Style Sheets",
//         "Creative Style System",
//         "Computer Style Sheet",
//         "Creative Sheet Style",
//       ],
//       type: "multiple",
//     },
//     {
//       question: "Describe CSS in your own words.",
//       type: "text",
//     },
//   ];

//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [answers, setAnswers] = useState({});

//   const handleOptionChange = (option, isChecked) => {
//     const currentQuestion = questions[currentQuestionIndex];

//     setAnswers((prevAnswers) => {
//       const prevAnswer = prevAnswers[currentQuestionIndex] || [];

//       if (currentQuestion.type === "single") {
//         return { ...prevAnswers, [currentQuestionIndex]: option };
//       }

//       if (currentQuestion.type === "multiple") {
//         const updatedAnswers = isChecked
//           ? [...prevAnswer, option]
//           : prevAnswer.filter((ans) => ans !== option);

//         return { ...prevAnswers, [currentQuestionIndex]: updatedAnswers };
//       }

//       return prevAnswers;
//     });
//   };

//   const handleTextChange = (event) => {
//     const value = event.target.value;
//     setAnswers((prevAnswers) => ({
//       ...prevAnswers,
//       [currentQuestionIndex]: value,
//     }));
//   };

//   const handleNext = () => {
//     if (currentQuestionIndex < questions.length - 1) {
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//     } else {
//       navigate("/submit");
//     }
//   };

//   const currentQuestion = questions[currentQuestionIndex];
//   const currentQuestionType = currentQuestion.type;

//   return (
//     <>
//       <Header />
//       <h2>Assessment</h2>
//       <div className="container">
//         <h2 className="effect">
//           {currentQuestionIndex + 1}. {currentQuestion.question}
//         </h2>
//         {currentQuestionType === "single" && (
//           <ul>
//             {currentQuestion.options.map((option, index) => (
//               <li key={index} style={{ textAlign: "start" }}>
//                 <label>
//                   <input
//                     type="radio"
//                     name={`question-${currentQuestionIndex}`}
//                     checked={answers[currentQuestionIndex] === option}
//                     onChange={() => handleOptionChange(option, true)}
//                     style={{ marginRight: "10px" }}
//                   />
//                   {option}
//                 </label>
//               </li>
//             ))}
//           </ul>
//         )}

//         {currentQuestionType === "multiple" && (
//           <ul>
//             {currentQuestion.options.map((option, index) => (
//               <li key={index} style={{ textAlign: "start" }}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     checked={answers[currentQuestionIndex]?.includes(option)}
//                     onChange={(e) =>
//                       handleOptionChange(option, e.target.checked)
//                     }
//                     style={{ marginRight: "10px" }}
//                   />
//                   {option}
//                 </label>
//               </li>
//             ))}
//           </ul>
//         )}

//         {currentQuestionType === "text" && (
//           <div>
//             <textarea
//               className="quiz-input"
//               type="tex"
//               rows={8}
//               style={{ textAlign: "start" }}
//               placeholder="Type your answer here..."
//               value={answers[currentQuestionIndex] || ""}
//               onChange={handleTextChange}
//             />
//           </div>
//         )}

//         <button onClick={handleNext}>Next</button>
//         <div className="index">
//           {currentQuestionIndex + 1} of {questions.length} questions
//         </div>
//       </div>
//     </>
//   );
// };

// export default QuizContainer;

import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import TopegrHeader from "../TopengrHeader";

const QuizContainer = () => {
  const navigate = useNavigate();
  const questions = [
    {
      question: "Which HTML tag is used to define a hyperlink?",
      options: ["<link>", "<a>", "<href>", "<url>"],
      type: "single",
    },
    {
      question:
        "What is the correct syntax for referring to an external script in HTML?",
      options: [
        "<script href='script.js'></script>",
        "<script src='script.js'></script>",
        "<script link='script.js'></script>",
        "<script file='script.js'></script>",
      ],
      type: "single",
    },
    {
      question: "Which of the following is a JavaScript framework?",
      options: ["Angular", "Django", "Laravel", "Flask"],
      type: "single",
    },
    {
      question: "What is the purpose of the CSS 'box-sizing' property?",
      options: [
        "To add margin around the element",
        "To set padding inside the element",
        "To define how the total width and height of an element is calculated",
        "To align elements horizontally",
      ],
      type: "single",
    },
    {
      question: "Which of the following is used to make a website responsive?",
      options: ["Bootstrap", "CSS Grid", "Flexbox", "All of the above"],
      type: "single",
    },
    {
      question: "What is the correct way to create a JavaScript function?",
      options: [
        "function myFunction() {}",
        "function: myFunction() {}",
        "var myFunction = function() {}",
        "Both 1 and 3",
      ],
      type: "multiple",
    },
    {
      question: "What is the default display value for <div> in HTML?",
      options: ["inline", "inline-block", "block", "flex"],
      type: "single",
    },
    {
      question: "Which CSS property is used to change the text color?",
      options: ["font-color", "color", "text-color", "background-color"],
      type: "single",
    },
    {
      question:
        "Which of the following are valid ways to select an element by class in CSS?",
      options: [".className", "#className", "className{}", ".className{}"],
      type: "multiple",
    },
    {
      question:
        "What is the Document Object Model (DOM) in the context of web development?",
      type: "text",
    },
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isFading, setIsFading] = useState(false);
  const [typedQuestion, setTypedQuestion] = useState("");
  const [charIndex, setCharIndex] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];
  const currentQuestionType = currentQuestion.type;

  useEffect(() => {
    setIsFading(true);
    setTypedQuestion(""); // Reset typed question when question changes
    setCharIndex(0);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (charIndex < currentQuestion.question.length) {
      const timeoutId = setTimeout(() => {
        setTypedQuestion(
          (prev) => prev + currentQuestion.question.charAt(charIndex)
        );
        setCharIndex((prev) => prev + 1);
      }, 50); // Adjust typing speed here
      return () => clearTimeout(timeoutId);
    }
  }, [charIndex, currentQuestion]);

  const handleOptionChange = (option, isChecked) => {
    const currentQuestion = questions[currentQuestionIndex];

    setAnswers((prevAnswers) => {
      const prevAnswer = prevAnswers[currentQuestionIndex] || [];

      if (currentQuestion.type === "single") {
        return { ...prevAnswers, [currentQuestionIndex]: option };
      }

      if (currentQuestion.type === "multiple") {
        const updatedAnswers = isChecked
          ? [...prevAnswer, option]
          : prevAnswer.filter((ans) => ans !== option);

        return { ...prevAnswers, [currentQuestionIndex]: updatedAnswers };
      }

      return prevAnswers;
    });
  };

  const handleTextChange = (event) => {
    const value = event.target.value;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestionIndex]: value,
    }));
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsFading(false);
    } else {
      navigate("/submitAssess");
    }
  };

  return (
    <>
      <TopegrHeader />
      <h2 className="text-center my-5">Assessment</h2>
      <div className={`container ${isFading ? "effect" : ""}`}>
        <div className="index">
          {currentQuestionIndex + 1}/{questions.length}
        </div>
        <h2>
          {currentQuestionIndex + 1}. {typedQuestion}
        </h2>
        {currentQuestionType === "single" && (
          <ul>
            {currentQuestion.options.map((option, index) => (
              <li key={index} style={{ textAlign: "start" }}>
                <label>
                  <input
                    type="radio"
                    name={`question-${currentQuestionIndex}`}
                    checked={answers[currentQuestionIndex] === option}
                    onChange={() => handleOptionChange(option, true)}
                    style={{ marginRight: "10px" }}
                  />
                  {option}
                </label>
              </li>
            ))}
          </ul>
        )}

        {currentQuestionType === "multiple" && (
          <ul>
            {currentQuestion.options.map((option, index) => (
              <li key={index} style={{ textAlign: "start" }}>
                <label>
                  <input
                    type="checkbox"
                    checked={answers[currentQuestionIndex]?.includes(option)}
                    onChange={(e) =>
                      handleOptionChange(option, e.target.checked)
                    }
                    style={{ marginRight: "10px" }}
                  />
                  {option}
                </label>
              </li>
            ))}
          </ul>
        )}

        {currentQuestionType === "text" && (
          <div>
            <textarea
              className="quiz-input"
              type="tex"
              rows={8}
              style={{ textAlign: "start" }}
              placeholder="Type your answer here..."
              value={answers[currentQuestionIndex] || ""}
              onChange={handleTextChange}
            />
          </div>
        )}

        <button onClick={handleNext}>Submit</button>
      </div>
    </>
  );
};

export default QuizContainer;
