import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import TopegrHeader from "../TopengrHeader";

const SubmitTest = () => {
  const navigate = useNavigate();
  return (
    <>
      <TopegrHeader />
      <div className="submitContainer">
        <h2>Thank you for completing the assessment...!</h2>
        <button
          onClick={() => {
            navigate("/quizassess");
          }}
        >
          Back To Home
        </button>
      </div>
    </>
  );
};

export default SubmitTest;
