import React, { useEffect, useState, useContext } from "react";
import Header from "../Header";
import { Table, Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { AppContext } from "../AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./style.css";
import TopegrHeader from "../TopengrHeader";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
const serverUrl = "https://developer.topengr.com/api";

const SearchJobList = () => {
  const { globalScodeVariable, setGlobalScodeVariable } =
    useContext(AppContext);
  const [jobs, setJobs] = useState([]);
  const [editJobId, setEditJobId] = useState(null);
  const navigate = useNavigate();
  const [editFormData, setEditFormData] = useState({
    searchTermId: "",
    searchTerm: "",
    notiFreq: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [deleteId, setDeleteID] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [backendendMsg, setBackendEnsMsg] = useState({ type: "", msg: "" });
  const headerType = localStorage.getItem("headerType");
  const authorizationHeader = globalScodeVariable;
  useEffect(() => {
    if (backendendMsg.type) {
      const timer = setTimeout(() => {
        setBackendEnsMsg({ type: "", msg: "" });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [backendendMsg]);

  const handleReadAllRequest = () => {
    const url = `${serverUrl}/v1/cn/ssrch`;
    fetch(url, {
      method: "GET",
      headers: { scode: authorizationHeader },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/email");
        } else if (response.status === 403) {
          throw new Error(
            "Access forbidden: You do not have permission to access this resource."
          );
        } else if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.m.type === 3) {
          setBackendEnsMsg({ type: data.m.type, msg: data.m.message });
        } else {
          setBackendEnsMsg({ type: data.m.type, msg: data.m.message });
          const jobsList = data.o;
          setJobs(jobsList);
        }
      })
      .catch((error) => {
        console.error("Fetch Error:", error);
      });
  };

  useEffect(() => {
    handleReadAllRequest();
  }, []);

  const handleEditClick = (job) => {
    setEditJobId(job.searchTermId);
    setEditFormData({
      searchTermId: job.searchTermId,
      searchTerm: job.searchTerm,
    });
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSaveClick = () => {
    fetch(`${serverUrl}/v1/cn/ssrch/u/${editJobId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        scode: authorizationHeader,
      },
      body: JSON.stringify(editFormData),
    })
      .then((response) => {
        setEditJobId(null);
        handleReadAllRequest();
      })
      .catch((error) => {
        console.error("Fetch Error:", error);
      });
  };
  const handleOk = () => {
    console.log(deleteId, "delete id ksdvuhjflfjnl");
    fetch(`${serverUrl}//v1/cn/ssrch/d/${deleteId}`, {
      method: "POST",
      headers: {
        scode: authorizationHeader,
      },
    })
      .then(() => {
        setDisplayDialog(false);
        setDeleteID(null);
        handleReadAllRequest();
      })
      .catch((error) => {
        console.error("Fetch Error:", error);
      });
  };

  const handleCancel = () => {
    setDisplayDialog(false);
    setDeleteID(null);
  };

  const handleDeleteClick = (jobId) => {
    setDisplayDialog(true);
    setDeleteID(jobId);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (key) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);
  };

  const filteredAndSortedJobs = jobs
    .filter(
      (job) =>
        job.searchTerm.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.searchTerm.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortKey === "fav") {
        return b.fav - a.fav;
      } else if (sortOrder === "asc") {
        return a[sortKey] > b[sortKey] ? 1 : -1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    });

  const notiFreqDisplay = (type) => {
    switch (type) {
      case "01": {
        return "Instant";
      }
      case "02": {
        return "Daily";
      }
      default:
        return "Unknown";
    }
  };

  const alertClass =
    backendendMsg.type === 1
      ? "alert alert-info"
      : backendendMsg.type === 2
      ? "alert alert-warning"
      : "alert alert-danger";

  return (
    <div>
      {headerType === "topengr" ? <TopegrHeader /> : <Header />}
      {backendendMsg.type && (
        <div
          class={alertClass}
          role="alert"
          style={{
            position: "fixed",
            top: "16px",
            right: "16px",
            alignItems: "end",
            justifyContent: "end",
            width: "fit-content",
            zIndex: 1000,
          }}
        >
          {backendendMsg.msg}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{ marginLeft: "20px", fontSize: "13px" }}
            onClick={() => setBackendEnsMsg({ type: "", msg: "" })}
          ></button>
        </div>
      )}
      <div className="job-list-content">
        <h2 className="mb-4 text-center mt-4" style={{ fontFamily: "cursive" }}>
          Search Query List
        </h2>

        <div className="m-5">
          <div className="d-flex flex-wrap justify-content-between mb-3 align-items-center">
            <div className="d-flex flex-grow-1 me-3 mb-3 mb-md-0 mb-lg-0">
              <InputGroup className="w-100">
                <InputGroup.Text>
                  <i className="bi bi-search custome_serach_hover"></i>
                </InputGroup.Text>
                <FormControl
                  className="custome_serach_hover"
                  placeholder="Search by job title or status"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </div>
          </div>

          <Table bordered responsive class="table table-striped table-hover">
            <thead>
              <tr className="text-center align-items-center justify-content-center">
                <th
                  className="tableHead"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#81b7ee",
                    color: "white",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  index
                </th>
                <th
                  className="tableHead"
                  onClick={() => handleSortChange("SearchTerm")}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#81b7ee",
                    color: "white",
                  }}
                >
                  Search Term
                </th>
                <th
                  className="tableHead"
                  onClick={() => handleSortChange("url")}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#81b7ee",
                    color: "white",
                  }}
                >
                  Notification Type
                </th>
                <th style={{ backgroundColor: "#81b7ee", color: "white" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="custom-row-color">
              {filteredAndSortedJobs.map((job, index) => (
                <tr key={job.searchTermId}>
                  {editJobId === job.searchTermId ? (
                    <>
                      <td>{index + 1}</td>
                      {/* <td>
                        <Form.Control
                          id="searchTermId"
                          type="text"
                          name="searchTermId"
                          value={editFormData.searchTermId}
                          onChange={handleEditFormChange}
                          disabled
                        />
                      </td> */}
                      <td>
                        <Form.Control
                          id="searchTerm"
                          type="text"
                          name="searchTerm"
                          value={editFormData.searchTerm}
                          onChange={handleEditFormChange}
                        />
                      </td>
                      <td>
                        <Form.Select
                          id="editStatus"
                          type="text"
                          name="notiFreq"
                          value={editFormData.status}
                          onChange={handleEditFormChange}
                        >
                          <option>Select Status</option>
                          <option value="01">Instant</option>
                          <option value="02">Daily</option>
                        </Form.Select>
                      </td>{" "}
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <i
                            id="editBtn"
                            className="bi bi-floppy2 text-success p-1 rounded"
                            style={{ cursor: "pointer" }}
                            onClick={handleSaveClick}
                          ></i>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{index + 1}</td>
                      <td>{job.searchTerm}</td>
                      <td>{notiFreqDisplay(job.notiFreq)}</td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <i
                            className="bi bi-pencil-square  text-success p-1 rounded"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditClick(job)}
                          ></i>
                          <i
                            id="deleteBtn"
                            className="bi bi-trash-fill text-danger p-1 rounded"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(job.searchTermId)}
                          ></i>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {displayDialog && (
        <Modal show={displayDialog} onHide={() => setDisplayDialog(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Job</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "35px",
              paddingTop: "5px",
              paddingBlock: "5px",
              border: "0px",
              borderTopColor: "white",
              border: "none",
            }}
          >
            <p>Are you sure you want to delete this job?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              id="confirmDelete"
              onClick={() => handleOk()}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default SearchJobList;
