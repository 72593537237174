import "./style.css";

const AuthHeader = () => {
  return (
    <>
      <div className="containerHeader">
        <div className="headerIcons">
          <img className="logo ms-2 bg-white rounded p-1" src="favicon.ico" />
        </div>
        <label className="welcomeMsg">Welcome</label>
      </div>
    </>
  );
};

export default AuthHeader;
