import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import TopegrHeader from "../TopengrHeader";
import Header from "../Header";
import { AppContext } from "../AppContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  const { globalScodeVariable, setGlobalScodeVariable } =
    useContext(AppContext);
  const [name, setName] = useState("name");
  const [phoneNo, setPhoneNo] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const scode = globalScodeVariable;
  const headerType = localStorage.getItem("headerType");
  const serverUrl = "https://developer.topengr.com/api";
  const navigate = useNavigate();
  const handleUpdateProfile = () => {
    const phoneRegex = /^[0-9]+$/;
    // if (!phoneNo.match(phoneRegex)) {
    //   setPhoneError("Phone number must contain only digits");
    //   return;
    // }

    console.log(phoneNo, "number");
    if (isEditing) {
      const data = { scode, name, countryCode, phoneNo };
      fetch(serverUrl + "/v1/ts/cn/uinfo/u", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          scode: scode,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 401) {
            navigate("/email");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data, "update data");
          getUserData();
          setName(data.o.name);
          setCountryCode(data.o.countryCode);
          setPhoneNo(data.o.phoneNo);
          localStorage.setItem("name", data.o.name);
        });
    }
    setIsEditing(!isEditing);
    setPhoneError("");
  };
  const getUserData = () => {
    fetch(serverUrl + "/v1/ts/cn/uinfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        scode: scode,
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "user data");
        data.o.name && setName(data.o.name);
        data.o.countryCode && setCountryCode(data.o.countryCode);
        data.o.phoneNo && setPhoneNo(data.o.phoneNo);
        console.log(data.o, "++++++++++++++");
        localStorage.setItem("name", data.o.name);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      {headerType === "topengr" ? <TopegrHeader /> : <Header />}
      <div className=" profileContainer mt-5">
        <div className="row justify-content-center">
          <div className="col-md-9 subContainer ">
            <div className="profileHeader text-center mb-4">
              <h1 className="display-6 fw-bold">Profile</h1>
            </div>
            <div className="profileContent d-flex flex-column flex-md-row align-items-center justify-content-center">
              <div className="profilePicture mb-3 mb-md-0">
                <img
                  src="favicon.ico"
                  alt="Profile"
                  className="img-fluid rounded-circle shadow"
                  style={{ height: "100px", width: "100px" }}
                />
              </div>
              <div className="profileDetails ml-md-4 text-center text-md-left ms-5">
                <div className="d-flex flex-row">
                  {isEditing ? (
                    <input
                      type="text"
                      className="form-control  mb-2 "
                      value={name}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  ) : (
                    <p className="ms-2 text-start" id="userName">
                      {name}
                    </p>
                  )}
                </div>

                <div className="d-flex flex-row">
                  {isEditing ? (
                    <>
                      <PhoneInput
                        containerStyle={{ width: "100%" }}
                        dropdownStyle={{
                          alignItems: "start",
                          justifyContent: "start",
                          textAlign: "start",
                        }}
                        country={"us"}
                        value={phoneNo}
                        onChange={(phone, country) => {
                          setPhoneNo(phone);
                          console.log(country);
                          setCountryCode(country.dialCode);
                        }}
                      />
                    </>
                  ) : (
                    <p className="ms-2 text-start" id="phoneNo">
                      +{phoneNo}
                    </p>
                  )}
                </div>
                {phoneError && <p className="text-danger ms-2">{phoneError}</p>}

                <div className="mt-3 justify-content-end align-self-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateProfile}
                  >
                    {isEditing ? "Save Changes" : "Update Profile"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
